import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import "./note.css";
import appconfig from '../appConfig'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthorization } from "../hooks/useAuthorization";
import * as Constants from "../helper/constants";
import { useValidation } from "../hooks/useValidation";
import { tokenRequest } from "../auth/authConfig";

function Note() {

    const apiServerUrl = appconfig.API_SERVER_URL;

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isValid] = useValidation();
    const isAuthenticated = useIsAuthenticated();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [username, setUsername] = useState("");
    const [noteTextError, setNoteTextError] = useState(false);
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [role, setRole] = useState("");
    const [noteId, setNoteId] = useState((null != searchParams.get("id")) ? Number(searchParams.get("id")) : 0);
    const [relatedId, setRelatedId] = useState((null != searchParams.get("relatedId")) ? Number(searchParams.get("relatedId")) : 0);
    const [clientFileId, setClientFileId] = useState((null != searchParams.get("clientFileId")) ? Number(searchParams.get("clientFileId")) : 0);
    const [noteText, setNoteText] = useState("");
    const [noteType, setNoteType] = useState((null != searchParams.get("noteTypeId")) ? Number(searchParams.get("noteTypeId")) : 0);
    const [createdBy, setCreatedBy] = useState(userLastName + ", " + userFirstName);
    const [createdByEmail, setCreatedByEmail] = useState("");
    const [createdOn, setCreatedOn] = useState(new Date());
    const [modifiedBy, setModifiedBy] = useState(userLastName + ", " + userFirstName);
    const [modifiedOn, setModifiedOn] = useState(new Date());
    const [noteTypes, setNoteTypes] = useState([]);

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount) {
            setUserFirstName(currentAccount.name.split(" ")[0]);
            setUserLastName(currentAccount.name.split(" ")[1]);
            setUsername(currentAccount.username);
            setCreatedBy(currentAccount.name.split(" ")[1] + ", " + currentAccount.name.split(" ")[0]);
            setModifiedBy(currentAccount.name.split(" ")[1] + ", " + currentAccount.name.split(" ")[0]);

            if (currentAccount.idTokenClaims.roles) {
                setRole(currentAccount.idTokenClaims.roles[0].toString());
            } else {
                setRole("No Role");
            }
        }
    }, [instance]);

    useEffect(() => {
        if ((null != noteId) && (noteId > 0)) {

            const FetchData = async (token) => {

                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'GET',
                    headers: headers,
                }

                const uri = apiServerUrl + 'NoteService/GetNote?id=' + noteId;

                fetch(uri, options)
                    .then(async response => {
                        const data = await response.json();

                        // check for error response
                        if (!response.ok) {
                            // get error message from body or default to response statusText
                            const error = (data && data.message) || response.statusText;
                            return Promise.reject(error);
                        }

                        setNoteId(data.noteId);
                        //setClientFileId(data.clientFileId);
                        setNoteText(data.noteText);
                        setNoteType(data.noteType.noteTypeId);
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            }


            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchData(response.accessToken);
                    }
                });
            }
        }
    }, [account, instance]);


    useEffect(() => {

        const FetchLookupData = async (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'NoteService/GetNoteLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setNoteTypes(data.noteTypes);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken);
                }
            });
        }
    }, [account, instance]);

    const onSaveClicked = () => {

        var result = 1;

        if ((null != noteId) && (noteId > 0)) {
            var note = {
                "noteId": noteId,
                "noteText": noteText,
                "noteTypeId": noteType,
                "relatedRecordId": relatedId.toString()
            };

            const PutData = (token) => {

                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'PUT',
                    headers: headers,
                    body: JSON.stringify(note)
                }

                const uri = apiServerUrl + 'NoteService/UpdateNote';

                result = fetch(uri, options)
                    .then(response => {
                        if (response.ok) {
                            navigate(navigateUrl());
                        } else {
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then()
                    .catch(error => alert("Update Note was not succesful."));
            };

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        PutData(response.accessToken);
                    }
                });
            }

        } else {
            if (noteText.length == 0) {
                setNoteTextError(true);
                return;
            }
            var note = {
                "noteId": noteId,
                "noteText": noteText,
                "noteTypeId": noteType,
                "relatedRecordId": relatedId.toString()
            };

            const PostData = (token) => {

                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(note)
                }

                const uri = apiServerUrl + 'NoteService/CreateNote?clientFileId=0';
                result = fetch(uri, options)
                    .then(response => {
                        if (response.ok) {
                            navigate(navigateUrl());
                        } else {
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then()
                    .catch(error => alert("Create Note was not succesful."));
            };

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        PostData(response.accessToken);
                    }
                });
            }
        }

        return result;

    };

    const onDeleteClicked = () => {

        var result = 1;

        if ((null != noteId) && (noteId > 0)) {

            const DeleteData = (token) => {

                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'DELETE',
                    headers: headers
                }

                result = fetch(apiServerUrl + 'NoteService/DeleteNote?id=' + noteId, options)
                    .then(response => {
                        if (response.ok) {
                            navigate(navigateUrl());
                        } else {
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then()
                    .catch(error => alert("Delete Note was not succesful."));
            };

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteData(response.accessToken);
                    }
                });
            }
        }

        return result;
    };

    const onCancelClicked = () => {
        navigate(navigateUrl());
    };

    const navigateUrl = () => {
        
        if (noteType == 4) {
            return "/agencyrequest?id=" + relatedId + "&clientFileId=" + clientFileId;
        }
        else if (noteType == 2) {
            return "/client-file?id=" + relatedId;
        }
    }
    const canSave = () => {
        var result = false;

        if ((null != noteId) && (noteId > 0)) {
            result = canUpdate("client-note", createdByEmail);
        } else {
            result = canCreate("client-note", null);
        }

        return result;
    }

    const onNoteTextChanged = (event) => {
        setNoteText(event.target.value);
        const fieldModal = { name: Constants.NOTE, value: event.target.value, required: true };
        setNoteTextError(!isValid(fieldModal));
    };


    if (canRead("client-note", createdByEmail)) {
        return (
            <div className="note">
                <div className="noteSection">
                    <div className="sectionTitle">Note</div><br />
                    <span className="hidden">
                        <input type="text" disabled="disabled" name="note-id" value={noteId} /><br />
                        <input type="text" disabled="disabled" name="client-file-id" value={relatedId} /><br /></span>
                    <label>
                        Note <br />
                        <textarea name="note-text" rows="10" cols="50"
                            value={noteText}
                            onChange={onNoteTextChanged} />
                    </label>
                    {noteTextError ? <span style={{ color: "red" }}><br />Please include note text to proceed.</span> : null}
                    <br /><br />
                    <label>
                        Note Type <br />
                        <select name="note-type" disabled="disabled" value={noteType}>
                            {noteTypes.map((nType) => <option value={nType.noteTypeId}>{nType.label}</option>)}
                        </select>
                    </label>
                    <br /><br />
                    <div className="commandBar">
                        <input type="button" value="Save" disabled={canSave() ? "" : "disabled"} className={`${canSave() ? "" : "hidden"}`} onClick={onSaveClicked} /> &nbsp;&nbsp;
                        <input type="button" value="Delete" disabled={(canDelete("client-note", username)) ? "" : "disabled"} className={`${canDelete("client-note", username) ? "" : "hidden"}`} onClick={onDeleteClicked} /> &nbsp;&nbsp;
                        <input type="button" value="Cancel" onClick={onCancelClicked} />
                    </div>
                </div>
                <div className="clientFileFooter">&nbsp;</div>
            </div>
        );
    } else {
        return (
            <div className="note">
                <div className="noteSection">
                    <div className="sectionTitle">Note</div><br />
                    <div>You are not authorized to read this record.</div>
                </div>
            </div>
        );
    }
}

export default Note;