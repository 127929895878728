import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import appConfig from "../../../appConfig";
import { tokenRequest } from "../../../auth/authConfig";
import { useAuthorization } from "../../../hooks/useAuthorization";
import AttachmentRow from "./attachment-row";
import { GetAttachments, UploadAttachment } from "../../../services/storage-service";

function AttachmentList(props: any) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const apiServerUrl = appConfig.API_SERVER_URL;
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [attachments, setAttachments] = useState(props.attachments);
    // Upload file related StateManagedSelect
    const [file, setFile] = useState<File>();
    const [isFilePicked, setIsFilePicked] = useState(false);

    useEffect(() => {
        loadFiles();
    }, []);

    const loadFiles = () => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }
    }

    const fetchInitialData = async (token: string) => {
        await GetAttachments(props.containerName, props.recordId, token).then((data) => {
            console.log(data);
            setAttachments(data);
        });
    }
    

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setIsFilePicked(true);
        }
    };

    const handleUploadClick = () => {
        if (!file) {
            return;
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    console.log(file);
                    const status = await UploadAttachment(props.containerName, props.recordId, file, response.accessToken);
                    if (status) {
                        alert("File successfully uploaded.");
                        setIsFilePicked(false);
                        loadFiles();
                    }
                    else
                        alert("File did not upload. Please try again.");
                }
            });
        }
    };

    return (
        <>
            <div id="attachment-component" className="border m-1 p-2">
                <div className="h6 text-center">Attachments</div>
                <div className="border m-1 p-2">
                    {/* Upload file here */}
                    <div className="d-inline p-1">
                        <label className="btn btn-primary">
                            Choose File <input type="file" hidden onChange={handleFileChange} />
                        </label>
                    </div>
                    {isFilePicked ? (
                        <>
                            <div className="d-inline p-1">
                                <button className=" btn btn-primary" onClick={handleUploadClick}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                </svg></button>
                            </div> </>) : (<>  </>)
                    }

                    <div className="p-1 m-1 fw-light fst-italic w-100">
                        {isFilePicked ? (
                            <>
                                <div>{file && `${file.name} - ${file.type}`}</div>
                            </>

                        ) : (
                            <>
                                Choose a file
                            </>

                        )}
                    </div>

                </div>
                <table>
                    <thead>
                        <tr>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Row goes here */}
                        {attachments ? attachments.map((attachment: any, i: string) => (
                            <AttachmentRow attachment={attachment} containerName={props.containerName} recordId={props.recordId} setAttachment={setAttachments} />
                        )) : null}
                    </tbody>
                </table>
            </div>

        </>
    );
}

export default AttachmentList;