
import React, { useEffect, useState, useRef } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./home.css";
import ClientFileTable from '../client-files';
import Reports from '../reports';
import appconfig from '../appConfig'
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { tokenRequest } from '../auth/authConfig';


function Home() {

  const [list, setList] = useState([]);

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const apiServerUrl = appconfig.API_SERVER_URL;

  useEffect(() => {
    
    const FetchData = (token: string) => {
      const headers = new Headers();
      const bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers
      };

      const uri = apiServerUrl + 'ClientFileService/GetClientFiles';

      fetch(uri, options)
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then(data => {
          setList(data);
        });
    };
    

    if (account) {
      instance.acquireTokenSilent(
        tokenRequest
      ).then((response) => {
        if (response) {
          FetchData(response.accessToken);
        }
      });
    }

  }, [account, instance]);

  return (
    <div className='home'>
      <Tabs
        defaultActiveKey="client-files"
        transition={false}
        id="client-files-tab"
        className="mb-3"
      >
        <Tab eventKey="client-files" title="Client Files">
          <ClientFileTable clientFiles={list} />
        </Tab>
        <Tab eventKey="reports" title="Reports">
          <Reports />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Home;