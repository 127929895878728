import "./referral-info.css";
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import Option from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import appconfig from '../appConfig'
import * as Constants from "../helper/constants";
import { useValidation } from "../hooks/useValidation";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";

const ReferralInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const apiServerUrl = appconfig.API_SERVER_URL;

    const DOCKERNUMBER = "DocketNumber";
    const COURTLOCATION = "CourtLocation";

    const EMPTYSTRING = "";

    const [isValid] = useValidation();
    const [referralId, setReferralId] = useState(props.referralId);
    const [clientFileId, setClientFileId] = useState(props.clientFileId);
    const [docketNumber, setDocketNumber] = useState("");
    const [docketNumberError, setDocketNumberError] = useState(false);
    const [upcomingCourtDate, setUpcomingCourtDate] = useState("");
    const [upcomingCourtDateError, setUpcomingCourtDateError] = useState(false);
    const [courtLocation, setCourtLocation] = useState("");
    const [courtLocationError, setCourtLocationError] = useState(false);
    const [dutyCounselType, setDutyCounselType] = useState(0);
    const [dutyCounselTypeError, setDutyCounselTypeError] = useState(false);

    useImperativeHandle(ref, () => ({
        getReferralInfo() {
            return {
                "referralId": referralId,
                "clientFile": {
                    "clientFileId": clientFileId
                },
                "docketNumber": docketNumber,
                "upcomingCourtDate": new Date(upcomingCourtDate),
                "courtLocation": JSON.stringify(courtLocation),
                "dutyCounselType": {
                    "dutyCounselTypeId": dutyCounselType,
                    "dutyCounselTypeCrmValue": 0,
                    "label": "",
                },
                "docketNumberValidity": docketNumberError,
                "upcomingCourtDateValidity": upcomingCourtDateError,
                "courtLocationValidity": courtLocationError,
                "dutyCounselTypeValidity": dutyCounselTypeError,
            };
        }
    }));

    useEffect(() => {

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };

            const uri = apiServerUrl + 'ReferralService/GetReferral?id=' + props.referralId;

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setReferralId(data.referralId);
                    setDocketNumber(data.docketNumber);
                    setUpcomingCourtDate(Date.parse(data.upcomingCourtDate));
                    setDutyCounselType(data.dutyCounselType.dutyCounselTypeId);
                    setCourtLocation(JSON.parse(data.courtLocation));
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }

        if (props.clientFileId) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchData(response.accessToken);
                    }
                });
            }
        }
    }, [account, instance]);

    function checkDocketNumber(event, inputName) {
        if (event.target.value.length == 0) {
            switch (inputName) {
                case DOCKERNUMBER:
                    setDocketNumber(EMPTYSTRING);
                    setDocketNumberError(false);
                    break;
                case COURTLOCATION:
                    setCourtLocation(EMPTYSTRING);
                    setCourtLocationError(false);
                    break;
                default:
                    break;
            }
        }
        else {
            const fieldModal = { name: Constants.DOCKETNUMBER, value: event.target.value, required: true, maxLength: 50 };
            const status = isValid(fieldModal);

            switch (inputName) {
                case DOCKERNUMBER:
                    setDocketNumberError(!status);
                    break;
                case COURTLOCATION:
                    setCourtLocationError(!status);
                    break;
                default:
                    break;
            }
        }
    }

    function checkCourtLocation(clValue, inputName) {
        if (clValue.length == 0) {
            switch (inputName) {
                case COURTLOCATION:
                    setCourtLocation(EMPTYSTRING);
                    setCourtLocationError(false);
                    break;
                default:
                    break;
            }
        }
        else {
            const fieldModal = { name: Constants.DOCKETNUMBER, value: clValue, required: true, maxLength: 50 };
            const status = isValid(fieldModal);

            switch (inputName) {
                case COURTLOCATION:
                    setCourtLocationError(!status);
                    break;
                default:
                    break;
            }
        }
    }



    const onDocketNumberChanged = (event) => {
        setDocketNumber(event.target.value);
        checkDocketNumber(event, DOCKERNUMBER);
    };
    const onUpcomingCourtDateChanged = (date) => {
        setUpcomingCourtDate(Date.parse(date));
    };

    const onDutyCounselTypeChanged = (event) => {
        setDutyCounselType(event.target.value);
    };

    const courtLocations = new Array();
 
    if ((null != props.courtLocations) && (props.courtLocations.length > 0)) {
        props.courtLocations.forEach((e, i) => {
            var cl = {}
            cl['value'] = e.replace(" ", "")
            cl['label'] = e
            courtLocations.push(cl);
        })
    }

    const onCourtLocationChanged = (value) => {
        setCourtLocation(value);
        checkCourtLocation(value, COURTLOCATION);
    };

    return (
        <div className="section">
            <div className="sectionTitle">Referral Information</div><br />
            <div className="hidden">
                <input type="hidden" name="referral-id" value={referralId} /><br />
                <input type="hidden" name="client-file-id" value={clientFileId} /><br />
            </div>
            <label>
                Docket Number <span style={{ color: "red" }}>*</span> <br />
                <input type="text" name="docket-number"
                    value={docketNumber}
                    onChange={onDocketNumberChanged} /> <br />
                {docketNumberError ? <span style={{ color: "red" }}>Docket Number is required and allows only upto a max limit of 50 characters</span> : null}
            </label>
            <br /><br />
            <label>
                Upcoming Court Date <span style={{ color: "red" }}>*</span> <br /></label>
            <DatePicker name="upcoming-court-date"
                selected={upcomingCourtDate}
                onChange={onUpcomingCourtDateChanged} /> <br />
            {upcomingCourtDateError ? <span style={{ color: "red" }}>Upcoming Court Date cannot be empty.</span> : null}
            <br /><br />
            <label>
                Court Location <span style={{ color: "red" }}>*</span> <br />
                <Select name="court-location" isMulti options={courtLocations} value={courtLocation} onChange={onCourtLocationChanged} />
                <br />
                {courtLocationError ? <span style={{ color: "red" }}>Court Location is required and allows only upto a max limit of 50 characters</span> : null}
            </label>
            <br /><br />
            <label>
                Duty Counsel Type <span style={{ color: "red" }}>*</span> <br />
                <select name="duty-counsel-type" value={dutyCounselType} onChange={onDutyCounselTypeChanged}>
                    <option value={0} >Please Select</option>
                    {props.dutyCounselTypes.map((dcType) => <option value={dcType.dutyCounselTypeId}>{dcType.label}</option>)}
                </select><br />
                {dutyCounselTypeError ? <span style={{ color: "red" }}>Duty Counsel Type is required</span> : null}
            </label>
            <br /><br />
        </div>
    );
})

export default ReferralInfo;