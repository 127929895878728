import React from "react";
import { forwardRef, useRef, useImperativeHandle, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientBasicInfo from "../client-basic-info";
import ClientContactInfo from "../client-contact-info";
import ClientFinancialInfo from "../client-financial-info";
import NoteTable from "../notes";
import ReferralTable from "../referrals";
import AgencyRequestList from "../feature/agency-requests/agency-request-list";
import "./client-file.css";
import appconfig from '../appConfig'
import { useAuthorization } from "../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import {GetAllAgencyRequest  } from "../services/agency-request-service";

function ClientFile() {

    const apiServerUrl = appconfig.API_SERVER_URL;

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [contactMethods, setContactMethods] = useState([]);
    const [agencyRequests, setAgencyRequests] = useState([]);

    const onSaveClicked = (event) => {

        var result = 1;

        if ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0)) {
            console.log(clientContactInfoComp.current.getClientContactInfo());
            if (validateInformation()) {
                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            var clientBasicInfoResult =
                                saveClientBasicInfo(
                                    clientBasicInfoComp.current.getClientBasicInfo(),
                                    response.accessToken
                                );

                            var clientContactInfoResult =
                                saveClientContactInfo(
                                    clientContactInfoComp.current.getClientContactInfo(),
                                    response.accessToken
                                );

                            var clientFinancialInfoResult =
                                saveClientFinancialInfo(
                                    clientFinancialInfoComp.current.getClientFinancialInfo(),
                                    response.accessToken
                                );
                        }
                    })
                }
            }
            else {
                alert("Please fill out the details correctly before saving the form.");
            }
        } else {
            var result = 0;
            var clientFile = {
                "clientFileId": 0,
                "clientFileCrmId": null,
                "clientBasicInformation": clientBasicInfoComp.current.getClientBasicInfo(),
                "clientContactDetails": clientContactInfoComp.current.getClientContactInfo(),
                "clientFinancialInformation": clientFinancialInfoComp.current.getClientFinancialInfo(),
                "notes": []
            };


            if (validateInformation()) {
                const PostData = (token) => {
                    const headers = new Headers();
                    const bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    headers.append('Content-Type', 'application/json');

                    const options = {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(clientFile)
                    };

                    const uri = apiServerUrl + 'ClientFileService/CreateClientFile';

                    result = fetch(uri, options)
                        .then(response => {
                            if (response.ok) {
                                navigate("/dashboard");
                            } else {
                                throw new Error('Something went wrong ...');
                            }
                        })
                        .then()
                        .catch(error => alert("Create Client File was not succesful."));
                }

                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            PostData(response.accessToken);
                        }
                    });
                }

            }
            else {
                alert("Please fill out the details correctly before saving the form.");
            }
        }

        return result;
    };


    const validateInformation = () => {
        if (
            clientBasicInfoComp.current.getClientBasicInfo().firstName && !clientBasicInfoComp.current.getClientBasicInfo().firstNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().lastName && !clientBasicInfoComp.current.getClientBasicInfo().lastNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth && !clientBasicInfoComp.current.getClientBasicInfo().dateOfBirthValidity &&
            clientContactInfoComp.current.getClientContactInfo().email && !clientContactInfoComp.current.getClientContactInfo().emailValidity &&
            clientContactInfoComp.current.getClientContactInfo().mobileNumber && !clientContactInfoComp.current.getClientContactInfo().phoneNumberValidity &&
            clientContactInfoComp.current.getClientContactInfo().phoneNumber && !clientContactInfoComp.current.getClientContactInfo().mobileNumberValidity) {
            return true;
        }

        return false;
    }

    const saveClientBasicInfo = (clientBasicInformation, token) => {
        const updateClientBasicInformation = apiServerUrl + 'ClientFileService/UpdateClientBasicInformation';

        var result = 0;

        const headers = new Headers();
        const bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        const options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(clientBasicInformation)
        }

        result = fetch(updateClientBasicInformation, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert('Update Client Basic Info Failed.');
                }
            })
            .then()
            .catch(error => alert('Update Client Basic Info Failed.'));

        return result;
    };

    const saveClientContactInfo = (clientContactInformation, token) => {

        const updateClientContactInformation = apiServerUrl + 'ClientFileService/UpdateClientContactInformation';

        var result = 0;

        const headers = new Headers();
        const bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');


        const options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(clientContactInformation)
        }

        result = fetch(updateClientContactInformation, options)
            .then(response => {
                if (response.ok) {
                    return Number(response.json());
                } else {
                    alert('Update Client Contact Info Failed.');
                }
            })
            .then()
            .catch(error => alert('Update Client Contact Info Failed.'));

        return result;
    };

    const saveClientFinancialInfo = (clientFinancialInformation, token) => {
        const updateClientFinancialInformation = apiServerUrl + 'ClientFileService/UpdateClientFinancialInformation';

        var result = 0;

        const headers = new Headers();
        const bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        const options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(clientFinancialInformation)
        }

        result = fetch(updateClientFinancialInformation, options)
            .then(response => {
                if (response.ok) {
                    navigate("/dashboard");
                } else {
                    alert('Update Client Financial Info Failed.');
                }
            })
            .then()
            .catch(error => alert('Update Client Financial Info Failed.'));

        return result;
    };

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const clientFinancialInfoComp = useRef();

    const [searchParams, setSearchParams] = useSearchParams();

    const [clientDetailNotes, setClientDetailNotes] = useState([]);
    useEffect(() => {

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'NoteService/GetClientDetailNotes?recordId=' + Number(searchParams.get("id"))+"&noteType=2";

            fetch(uri, options)
                .then(response => response.json())
                .then(setClientDetailNotes);
        };

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchData(response.accessToken);
                }
            });
        }

    }, [account, instance]);

    const [clientReferrals, setClientReferrals] = useState([]);    
    useEffect(() => {

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ReferralService/GetClientReferrals?clientFileId=' + Number(searchParams.get("id"));

            fetch(uri, options)
                .then(response => response.json())
                .then(setClientReferrals);
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchData(response.accessToken);
                }
            });
        }

    }, [account, instance]);

    useEffect(() => {

        const FetchLookupData = async (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ClientFileService/GetClientFileLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setContactMethods(data.contactMethods);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken);
                }
            });
        }
    }, [account, instance]);

    
    useEffect(() => {        
        const fetchInitialData = async (token) => {            
                await GetAllAgencyRequest(Number(searchParams.get("id")), token).then( (data) =>{
                    setAgencyRequests(data);
                });                
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {                    
                    fetchInitialData(response.accessToken);
                }
            });
        }
       

    }, [account, instance]);

    const onDeleteClicked = (event) => {

        var result = 1;

        const DeleteData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'DELETE',
                headers: headers,
            }

            const uri = apiServerUrl + 'ClientFileService/DeleteClientFile?id=' + searchParams.get("id");

            result = fetch(uri, options)
                .then(response => {
                    if (response.ok) {
                        navigate("/dashboard");
                    } else {
                        throw new Error('Something went wrong ...');
                    }
                })
                .then()
                .catch(error => alert("Delete Client File was not succesful."));
        };


        const thereAreNoSearchParameters = (null != searchParams.get("id"));
        const thereIsAtLeastOneSearchParameter = (Number(searchParams.get("id")) > 0);

        if (thereAreNoSearchParameters && thereIsAtLeastOneSearchParameter) {

            let text;

            const confirmationMessage = "Are you sure you want to delete the Client File and all the details (Notes, Referrals, Etc.) associated with it?";

            if (window.confirm(confirmationMessage) == true) {

                text = "You pressed OK!";

                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            DeleteData(response.accessToken);
                        }
                    });
                }
            } else {
                text = "You canceled!";
            }
        }

        return result;
    }

    const canSave = () => {
        var result = false;

        if ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0)) {
            result = canUpdate("client-file", null);
        } else {
            result = canCreate("client-file", null);
        }

        return result;
    }

    if (canRead("client-file", null)) {

        return (
            <div className="clientFile">
                <div className="clientDetails">
                    <div className="sectionTitle"><div className="columnLeft">Client Details</div>
                        <div className="columnRight">
                            <input type="button" hidden={((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0)) ? "" : "hidden"} value="Delete" disabled={(canDelete("client-file", null)) ? "" : "disabled"} className={`delete-button ${canDelete("client-file", null) ? "" : "hidden"}`} onClick={onDeleteClicked} />
                        </div>
                    </div>
                    <input type="hidden" name="client-file-id" value={searchParams.get(" id")} /><br />
                    <ClientBasicInfo clientFileId={searchParams.get("id")} ref={clientBasicInfoComp} isAliasVisible={true} />
                    <ClientContactInfo clientFileId={searchParams.get("id")} ref={clientContactInfoComp} contactMethods={contactMethods} />
                    <ClientFinancialInfo isCRMOnly={true} clientFileId={searchParams.get("id")} ref={clientFinancialInfoComp} />
                    <div className="commandBar">
                        <input type="button" value="Save" disabled={canSave() ? "" : "disabled"} className={`${canSave() ? "" : "hidden"}`} onClick={onSaveClicked} /> &nbsp;&nbsp;
                    </div>
                </div>
                <NoteTable title="Client Detail Notes" notes={clientDetailNotes} clientFileId={Number(searchParams.get("id"))} relatedId={Number(searchParams.get("id"))}  noteTypeId={2} />
                <ReferralTable title="Client Referrals" referrals={clientReferrals} clientFileId={Number(searchParams.get("id"))} />
                <AgencyRequestList title="Agency Requests" agencyRequests={agencyRequests} clientFileId={Number(searchParams.get("id"))} />
                <div className="clientFileFooter">&nbsp;</div>
            </div>
        );
    } else {
        return (
            <div className="clientFile">
                <div className="clientDetails">
                    <div className="sectionTitle">Client Details</div><br />
                    <div>You are not authorized to read this record.</div>
                </div>
            </div>
        );
    }
}

export default ClientFile;