import "./client-contact-info.css";
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import appconfig from '../appConfig'
import { useValidation } from "../hooks/useValidation";
import * as Constants from "../helper/constants";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";

const ClientContactInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const apiServerUrl = appconfig.API_SERVER_URL;

    const EMPTYSTRING = "";

    const [isValid] = useValidation();
    const [clientContactInformationId, setClientContactInformationId] = useState(0);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState(false);
    const [preferredMethodOfContact, setPreferredMethodOfContact] = useState(1);

    useImperativeHandle(ref, () => ({
        getClientContactInfo() {
            return {
                "clientContactInfoId": clientContactInformationId,
                "email": email,
                "phoneNumber": phoneNumber,
                "mobileNumber": mobileNumber,
                "emailValidity": emailError,
                "phoneNumberValidity": phoneNumberError,
                "mobileNumberValidity": mobileNumberError,
                "preferredMethodOfContact": {
                    "contactMethodId": preferredMethodOfContact,
                    "contactMethodCrmValue": 0,
                    "label": "string"
                }
            };
        }
    }));

    useEffect(() => {

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };

            const uri = apiServerUrl + 'ClientFileService/GetClientContactInformation?clientFileId=' + props.clientFileId;

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setClientContactInformationId(data.clientContactInfoId);
                    setEmail(data.email);
                    setPhoneNumber(data.phoneNumber);
                    setMobileNumber(data.mobileNumber);
                    setPreferredMethodOfContact(data.preferredMethodOfContact.contactMethodId);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
        if (props.clientFileId) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchData(response.accessToken);
                    }
                });
            }
        }
    }, [account, instance]);

    function checkEmail(event) {
        if (event.target.value.length == 0) {
            setEmail(EMPTYSTRING);
            setEmailError(false);
        }
        else {
            const emailFieldModal = { name: Constants.EMAIL, value: event.target.value, required: true };
            setEmailError(!isValid(emailFieldModal));
        }
    }

    function checkPhoneNumber(event, phoneType) {
        const phoneFieldModal = { name: Constants.PHONENUMBER, value: event.target.value, required: true, minLength: 10, maxLength: 11 };
        const phoneStatus = isValid(phoneFieldModal);

        switch (phoneType) {
            case 1:
                setPhoneNumberError(!phoneStatus);
                break;
            case 2:
                setMobileNumberError(!phoneStatus);
                break;
            default:
                break;
        }
    }

    const onEmailChanged = (event) => {
        setEmail(event.target.value);
        checkEmail(event);
    };
    const onPhoneNumberChanged = (event) => {
        setPhoneNumber(event.target.value);
        checkPhoneNumber(event, 1);
    };
    const onMobileNumberChanged = (event) => {
        setMobileNumber(event.target.value);
        checkPhoneNumber(event, 2);
    };
    const onPreferredMethodOfContactChanged = (event) => {
        setPreferredMethodOfContact(event.target.value);
    };

    return (

        <div className={`section ${props.isReadOnly ? "read-only" : ""}`} >
            <div className="sectionTitle">Contact Information</div><br />
            <div className="hidden">
                <input type="hidden" name="client-contact-info-id" disabled={props.isReadOnly ? "disabled" : ""} value={clientContactInformationId} /><br />
            </div>
            <label>
                Email <span style={{ color: "red" }}>*</span> <br />
                <input type="text" name="email" disabled={props.isReadOnly ? "disabled" : ""} value={email} onChange={onEmailChanged} /> <br />
                {emailError ? <span style={{ color: "red" }}>Please enter a valid email.</span> : null}
            </label>
            <br /><br />
            <label>
                Phone Number <span style={{ color: "red" }}>*</span> <br />
                <input type="text" name="phone-number" disabled={props.isReadOnly ? "disabled" : ""} value={phoneNumber} onChange={onPhoneNumberChanged} /> <br />
                {phoneNumberError ? <span style={{ color: "red" }}>Please provide a valid phone number</span> : null}
            </label>
            <br /><br />
            <label>
                Mobile Number <span style={{ color: "red" }}>*</span> <br />
                <input type="text" name="mobile-number" disabled={props.isReadOnly ? "disabled" : ""} value={mobileNumber} onChange={onMobileNumberChanged} /> <br />
                {mobileNumberError ? <span style={{ color: "red" }}>Please provide a valid mobile number</span> : null}
            </label><br /><br />

            <label>
                Preferred Method of Contact <span style={{ color: "red" }}>*</span> <br />
                <select name="preferred-method-of-contact" disabled={props.isReadOnly ? "disabled" : ""} value={preferredMethodOfContact} onChange={onPreferredMethodOfContactChanged}>
                    {props.contactMethods.map((contactMethod) => <option value={contactMethod.contactMethodId}>{contactMethod.label}</option>)}
                </select>
            </label><br /><br />
        </div>
    );
})

export default ClientContactInfo;