import React, { useEffect, useState, useRef } from 'react';
import { Button } from "react-bootstrap";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthorization } from "../../../hooks/useAuthorization";

function AgencyRequestRow(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();

    const formatDate = function (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    const agencyRequest = props.agencyRequest;
    if (canRead("agency-request", null)) {
        return (
            <tr>
                <td><a className="agencyRequestRow" href={'/agencyrequest?id=' + agencyRequest.agencyRequestId + '&clientFileId=' + props.clientFileId}>{agencyRequest.docketNumber}</a></td>
                <td>{formatDate(agencyRequest.upcomingCourtDate)}</td>

                <td>{agencyRequest.requestor? JSON.parse(agencyRequest.requestor).label: null}</td>
                <td>{agencyRequest.assignedTo? JSON.parse(agencyRequest.assignedTo).label: null} </td>
               
                <td>{agencyRequest.courtLocation? JSON.parse(agencyRequest.courtLocation).label: null}</td>
                <td>{agencyRequest.courtRoom}</td>                
                <td>{agencyRequest.outcomeStatusDetails? agencyRequest.outcomeStatusDetails.label: ""}</td>
            </tr>
        );
    } else {
        return (<tr><td colSpan={7}>You are not authorized to read this record.</td></tr>);
    }
}

function AgencyRequestList(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const rows = [];

    if (props.agencyRequests && props.agencyRequests.length > 0) {
        props.agencyRequests.forEach((agencyRequest) => {
            rows.push(
                <AgencyRequestRow agencyRequest={agencyRequest} clientFileId={props.clientFileId} />
            );
        });
    }
    if (props.clientFileId && props.clientFileId > 0) {
        return (
            <div className="agencyRequestsSection">
                <div className="sectionTitle">{props.title}</div><br />
                <div className="agencyRequests">
                    <div>
                        <div>
                            <Button variant={(canCreate("agency-request", null)) ? "primary" : "secondary"} disabled={(canCreate("agency-request", null)) ? "" : "disabled"} className="ml-auto" href={'/agencyrequest?clientFileId=' + props.clientFileId}>New Agency Request</Button>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                            <th>Docket Number</th>
                                <th>Upcoming Court Date</th>
                                <th>Requestor</th>
                                <th>Assigned to</th>                                
                                <th>Court Location</th>                                
                                <th>Court Room</th>                               
                                <th>Outcome Status</th>                               
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                </div >
            </div>
        );
    } else {
        return (
            <div className="agencyRequestsSection">
                <div className="sectionTitle">{props.title}</div><br />

                <div className="agencyRequests">
                    Save the new Client File to add new Agency Request.
                </div >
            </div>
        );
    }
}

export default AgencyRequestList;
