import { Button } from "react-bootstrap";
import "./notes.css";
import React, { useEffect, useState, useRef } from 'react';
import { useAuthorization } from "../hooks/useAuthorization";

function NoteRow(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
        const formatDate = function(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        };

    const note = props.note;

    if (canRead("client-note", note.createdBy.email)) {
        return (
            <tr>
                <td><a className="noteRow" href={'/note?id=' + note.noteId + '&relatedId=' + props.relatedId+'&clientFileId='+props.clientFileId}>{note.noteText}</a></td>
            </tr>
        );
    } else {
        return (<tr><td>You are not authorized to read this record.</td></tr>);
    }
}

function NoteTable(props) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const rows = [];

    if (props.notes && props.notes.length > 0) {
        props.notes.forEach((note) => {
            rows.push(
                <NoteRow note={note} clientFileId={props.clientFileId} relatedId={props.relatedId} />
            );
        });
    }

    if (props.clientFileId && props.clientFileId > 0) {
        return (
            <div className="notesSection">
                <div className="sectionTitle">{props.title}</div><br />

                <div className="notes">
                    <div>
                        <div>
                            <Button variant={(canCreate("client-note", null)) ? "primary" : "secondary"} disabled={(canCreate("client-note", null)) ? "" : "disabled"} className="ml-auto" href={'/note?noteTypeId=' + props.noteTypeId + '&relatedId=' + props.relatedId+'&clientFileId='+props.clientFileId}>New Note</Button>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Note Text</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                </div >
            </div>
        );
    } else {
        return (
            <div className="notesSection">
                <div className="sectionTitle">{props.title}</div><br />

                <div className="notes">
                    Save the new Client File to add new Notes.
                </div >
            </div>
        );
    }
}

export default NoteTable;
