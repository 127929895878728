import "./client-basic-info.css";
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import appconfig from '../appConfig'
import * as Constants from "../helper/constants";
import { useValidation } from "../hooks/useValidation";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";


const ClientBasicInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const apiServerUrl = appconfig.API_SERVER_URL;

    const FIRSTNAME = "FirstName";
    const LASTNAME = "LastName";
    const CLIENTALIAS1 = "ClientAlias1";
    const CLIENTALIAS2 = "ClientAlias2";
    const CLIENTALIAS3 = "ClientAlias3";
    const EMPTYSTRING = "";

    const [isValid] = useValidation();
    const [clientBasicInformationId, setClientBasicInformationId] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [dateOfBirthError, setDateOfBirthError] = useState(false);
    const [clientAlias1, setClientAlias1] = useState("");
    const [clientAlias1Error, setClientAlias1Error] = useState(false);
    const [clientAlias2, setClientAlias2] = useState("");
    const [clientAlias2Error, setClientAlias2Error] = useState(false);
    const [clientAlias3, setClientAlias3] = useState("");
    const [clientAlias3Error, setClientAlias3Error] = useState(false);

    useImperativeHandle(ref, () => ({
        getClientBasicInfo() {
            return {
                "clientBasicInformationId": clientBasicInformationId,
                "firstNameValidity": firstNameError,
                "lastNameValidity": lastNameError,
                "dateOfBirthValidity": dateOfBirthError,
                "clientAlias1Validity": clientAlias1Error,
                "clientAlias2Validity": clientAlias2Error,
                "clientAlias3Validity": clientAlias3Error,
                "firstName": firstName,
                "lastName": lastName,
                "dateOfBirth": new Date(dateOfBirth),
                "clientAlias1": clientAlias1,
                "clientAlias2": clientAlias2,
                "clientAlias3": clientAlias3
            };
        }
    }));

    useEffect(() => {

        const FetchData = (token) => {


            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };

            const uri = apiServerUrl + 'ClientFileService/GetClientBasicInformation?clientFileId=' + props.clientFileId;

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setClientBasicInformationId(data.clientBasicInformationId);
                    setFirstName(data.firstName);
                    setLastName(data.lastName);
                    setDateOfBirth(Date.parse(data.dateOfBirth));
                    setClientAlias1(data.clientAlias1);
                    setClientAlias2(data.clientAlias2);
                    setClientAlias3(data.clientAlias3);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        };

        if (props.clientFileId) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchData(response.accessToken);
                    }
                });
            }
        };


    }, [account, instance]);


    function checkName(event, inputName) {
        if (event.target.value.length == 0) {
            switch (inputName) {
                case FIRSTNAME:
                    setFirstName(EMPTYSTRING);
                    setFirstNameError(false);
                    break;
                case LASTNAME:
                    setLastName(EMPTYSTRING);
                    setLastNameError(false);
                    break;
                default:
                    break;
            }
        }
        else {
            const nameFieldModal = { name: Constants.NAME, value: event.target.value, required: true, maxLength: 50 };
            const status = isValid(nameFieldModal);

            switch (inputName) {
                case FIRSTNAME:
                    setFirstNameError(!status);
                    break;
                case LASTNAME:
                    setLastNameError(!status);
                    break;
                case CLIENTALIAS1:
                    setClientAlias1Error(!status);
                    break;
                case CLIENTALIAS2:
                    setClientAlias2Error(!status);
                    break;
                case CLIENTALIAS3:
                    setClientAlias3Error(!status);
                    break;
                default:
                    break;
            }
        }
    }

    const onFirstNameChanged = (event) => {
        setFirstName(event.target.value);
        checkName(event, FIRSTNAME);
    };
    const onLastNameChanged = (event) => {
        setLastName(event.target.value);
        checkName(event, LASTNAME);
    };
    const onDateOfBirthChanged = (date) => {
        setDateOfBirth(Date.parse(date));
    };

    const onClientAlias1Changed = (event) => {
        setClientAlias1(event.target.value);
        checkName(event, CLIENTALIAS1);
    };
    const onClientAlias2Changed = (event) => {
        setClientAlias2(event.target.value);
        checkName(event, CLIENTALIAS2);
    };
    const onClientAlias3Changed = (event) => {
        setClientAlias3(event.target.value);
        checkName(event, CLIENTALIAS3);
    };

    return (
        <div className={`section ${props.isReadOnly ? "read-only" : ""}`}>
            <div className="sectionTitle">Basic Information</div><br />
            <div className="hidden">
                <input type="hidden" name="client-basic-info-id" disabled={props.isReadOnly ? "disabled" : ""} value={clientBasicInformationId} /><br />
            </div>
            <label>
                First Name <span style={{ color: "red" }}>*</span>  </label><br />
            <input type="text" name="first-name" disabled={props.isReadOnly ? "disabled" : ""}
                value={firstName}
                onChange={onFirstNameChanged} /> <br />
            {firstNameError ? <span style={{ color: "red" }}>Only characters allowed for First Name upto a max limit of 50 characters</span> : null}

            <br /><br />
            <label>
                Last Name <span style={{ color: "red" }}>*</span> </label> <br />
            <input type="text" name="last-name" disabled={props.isReadOnly ? "disabled" : ""}
                value={lastName}
                onChange={onLastNameChanged} /> <br />
            {lastNameError ? <span style={{ color: "red" }}>Only characters allowed for Last Name upto a max limit of 50 characters</span> : null}

            <br /><br />
            <label>
                Date of Birth <span style={{ color: "red" }}>*</span> </label><br />
            <DatePicker name="date-of-birth" disabled={props.isReadOnly ? "disabled" : ""}
                selected={dateOfBirth}
                onChange={onDateOfBirthChanged}
            /> <br />
            {dateOfBirthError ? <span style={{ color: "red" }}>Date of Birth cannot be blank</span> : null}

            <br /><br />
            {props.isAliasVisible  &&
            <div className="crm-only">
                <label>
                    Client Alias 1 </label><br />
                <input type="text" name="client-alias-1" disabled="disabled"
                    value={clientAlias1}
                    onChange={onClientAlias1Changed} /> <br />
                {clientAlias1Error ? <span style={{ color: "red" }}>Only characters allowed for Client Alias 1 upto a max limit of 50 characters</span> : null}

                <br /><br />

                <label>
                    Client Alias 2   </label><br />
                <input type="text" name="client-alias-2" disabled="disabled"
                    value={clientAlias2}
                    onChange={onClientAlias2Changed} /> <br />
                {clientAlias2Error ? <span style={{ color: "red" }}>Only characters allowed for Client Alias 2 upto a max limit of 50 characters</span> : null}

                <br /><br />

                <label>
                    Client Alias 3 </label><br />
                <input type="text" name="client-alias-3" disabled="disabled"
                    value={clientAlias3}
                    onChange={onClientAlias3Changed} /> <br />
                {clientAlias3Error ? <span style={{ color: "red" }}>Only characters allowed for Client Alias 3 upto a max limit of 50 characters</span> : null}

                <br /><br />
            </div>}
        </div>
    );
})

export default ClientBasicInfo;