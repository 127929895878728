import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <>
            <div>
                <div>
                    <Button variant="secondary" className="ml-auto" onClick={() => handleLogout("redirect")}>Sign out </Button>
                </div>
                <div>
                    <a href="https://passwordreset.microsoftonline.com/" target="_blank">Change password?</a><br /><br />
                    <a href="/dashboard">Dashboard</a>
                </div>
            </div>


        </>
    );
}