const Welcome = ({ message }: { message: string }) => (
  <div className="row welcome d-flex pt-3 pb-3 align-items-center text-center">
    <div className="col welcome-bg bg-image">
      <div className="col text-white mb-0 welcome-message d-flex justify-content-left align-items-top h-100">
        {message}
      </div>
    </div>
  </div>
);

export default Welcome;
