import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";
import Button from "react-bootstrap/Button";
import { WelcomeName } from "../main-page/WelcomeName";
import { Routes, Route, useNavigate } from 'react-router-dom';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();

    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
                navigate('/dashboard');
            });
        }
    }

    return (
        <>
            <div>
                <div>
                    <Button variant="secondary" className="ml-auto" onClick={() => handleLogin("redirect")}>Sign in</Button>
                </div>
                <div>
                    <a href="https://passwordreset.microsoftonline.com/" target="_blank">Forgot password?</a>
                </div>
            </div>
        </>
    );
}