import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "../auth/SignInButton,";
import { SignOutButton } from "../auth/SignOutButton";
import { useState, useEffect } from "react";


const ProfileContent = () => {

    const isAuthenticated = useIsAuthenticated();

    const { instance } = useMsal();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount) {
            setUsername(currentAccount.username);
            if (currentAccount.idTokenClaims.roles) {
                setRole(currentAccount.idTokenClaims.roles[0].toString());
            }else{
                setRole("No Role");
            }
        }
    }, [instance]);

    return (
        <>
            <div className="col-md-12">
                <div className="d-flex justify-content-end">
                    {role ? role : "null"}
                </div>
                <div className="d-flex justify-content-end">
                    {username ? username : "null"}
                </div>
            </div>
        </>

    );
}



export default ProfileContent;
