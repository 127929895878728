import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientBasicInfo from "../client-basic-info";
import ClientContactInfo from "../client-contact-info";
import ReferralInfo from "../referral-info";
import "./referral.css";
import appconfig from '../appConfig'
import { useAuthorization } from "../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";

function Referral() {
    
    const apiServerUrl = appconfig.API_SERVER_URL;

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [referralId, setReferralId] = useState(searchParams.get("id"));
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [courtLocations, setCourtLocations] = useState([]);
    const [dutyCounselTypes, setDutyCounselTypes] = useState([]);
    const [contactMethods, setContactMethods] = useState([]);

    const onSaveClicked = (event) => {

        var result = 1;

        if ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0)) {

            if (validateInformation()) {

                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            var clientBasicInfoResult =
                                saveClientBasicInfo(
                                    clientBasicInfoComp.current.getClientBasicInfo(),
                                    response.accessToken
                                );

                            var clientContactInfoResult =
                                saveClientContactInfo(
                                    clientContactInfoComp.current.getClientContactInfo(),
                                    response.accessToken
                                );

                            var ReferralInfoResult =
                                saveReferralInfo(
                                    referralInfoComp.current.getReferralInfo(),
                                    response.accessToken
                                );
                        }
                    })
                }
            }
            else {
                alert("Please fill out the details correctly before saving the form.");
            }
        }
        else {
            var result = 0;
            var referral = {
                "referralId": 0,
                "referralCrmId": null,
                "clientBasicInformation": clientBasicInfoComp.current.getClientBasicInfo(),
                "clientContactDetails": clientContactInfoComp.current.getClientContactInfo(),
                "docketNumber": referralInfoComp.current.getReferralInfo().docketNumber,
                "upcomingCourtDate": referralInfoComp.current.getReferralInfo().upcomingCourtDate,
                "courtLocation": referralInfoComp.current.getReferralInfo().courtLocation,
                "dutyCounselType": referralInfoComp.current.getReferralInfo().dutyCounselType,
                "clientFile": referralInfoComp.current.getReferralInfo().clientFile
            };

            if (validateInformation()) {

                const PostData = (token) => {

                    const headers = new Headers();
                    const bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    headers.append('Content-Type', 'application/json');

                    const options = {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(referral)
                    }

                    const uri = apiServerUrl + 'ReferralService/CreateReferral';

                    result = fetch(uri, options)
                        .then(response => {
                            if (response.ok) {
                                navigate("/client-file?id=" + clientFileId);
                            } else {
                                throw new Error('Something went wrong ...');
                            }
                        })
                        .then()
                        .catch(error => alert("Create Referral was not succesful."));
                };

                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            PostData(response.accessToken);
                        }
                    });
                }
            }
            else {
                alert("Please fill out the details correctly before saving the form.");
            }
        }

        return result;
    }

    const validateInformation = () => {
        if (clientBasicInfoComp.current.getClientBasicInfo().firstName && !clientBasicInfoComp.current.getClientBasicInfo().firstNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().lastName && !clientBasicInfoComp.current.getClientBasicInfo().lastNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth && !clientBasicInfoComp.current.getClientBasicInfo().dateOfBirthValidity &&
            clientContactInfoComp.current.getClientContactInfo().email && !clientContactInfoComp.current.getClientContactInfo().emailValidity &&
            clientContactInfoComp.current.getClientContactInfo().mobileNumber && !clientContactInfoComp.current.getClientContactInfo().phoneNumberValidity &&
            clientContactInfoComp.current.getClientContactInfo().phoneNumber && !clientContactInfoComp.current.getClientContactInfo().mobileNumberValidity
            && referralInfoComp.current.getReferralInfo().docketNumber && !referralInfoComp.current.getReferralInfo().docketNumberValidity
            && referralInfoComp.current.getReferralInfo().upcomingCourtDate && !referralInfoComp.current.getReferralInfo().upcomingCourtDateValidity
            && referralInfoComp.current.getReferralInfo().courtLocation && !referralInfoComp.current.getReferralInfo().courtLocationValidity
            && referralInfoComp.current.getReferralInfo().dutyCounselType && !referralInfoComp.current.getReferralInfo().dutyCounselTypeValidity) {
            return true;
        }

        return false;
    }

    const saveClientBasicInfo = (clientBasicInformation, token) => {
        // ReadOnly
        return true;

        const updateClientBasicInformation = apiServerUrl + 'ClientFileService/UpdateClientBasicInformation';

        var result = 0;

        const headers = new Headers();
        const bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        const options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(clientBasicInformation)
        }

        result = fetch(updateClientBasicInformation, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert('Update Client Basic Info Failed.');
                }
            })
            .then()
            .catch(error => alert('Update Client Basic Info Failed.'));

        return result;
    };

    const saveClientContactInfo = (clientContactInformation, token) => {
        // ReadOnly
        return true;

        const updateClientContactInformation = apiServerUrl + 'ClientFileService/UpdateClientContactInformation';

        var result = 0;

        const headers = new Headers();
        const bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        const options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(clientContactInformation)
        }

        result = fetch(updateClientContactInformation, options)
            .then(response => {
                if (response.ok) {
                    return Number(response.json());
                } else {
                    alert('Update Client Contact Info Failed.');
                }
            })
            .then()
            .catch(error => alert('Update Client Contact Info Failed.'));

        return result;
    };

    const saveReferralInfo = (referral, token) => {

        const updateReferral = apiServerUrl + 'ReferralService/UpdateReferral';

        var result = 0;

        const headers = new Headers();
        const bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        const options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(referral)
        }

        result = fetch(updateReferral, options)
            .then(response => {
                if (response.ok) {
                    navigate("/client-file?id=" + clientFileId);
                } else {
                    alert('Update Referral Failed.');
                }
            })
            .then()
            .catch(error => alert('Update Referral Failed.'));

        return result;
    };

    const onDeleteClicked = () => {

        var result = 1;

        if ((null != referralId) && (referralId > 0)) {

            const DeleteData = (token) => {
                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'DELETE',
                    headers: headers
                }

                const uri = apiServerUrl + 'ReferralService/DeleteReferral?id=' + referralId;

                result = fetch(uri, options)
                    .then(response => {
                        if (response.ok) {
                            navigate("/client-file?id=" + clientFileId);
                        } else {
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then()
                    .catch(error => alert("Delete Referral was not succesful."));
            };

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteData(response.accessToken);
                    }
                });
            }
        }

        return result;
    };

    useEffect(() => {

        const FetchLookupData = async (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ReferralService/GetReferralLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setCourtLocations(data.courtLocations);
                    setDutyCounselTypes(data.dutyCounselTypes);
                    setContactMethods(data.contactMethods);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken);
                }
            });
        }
    }, [account, instance]);

    const canSave = () => {
        var result = false;

        if ((null != referralId) && (referralId > 0)) {
            result = canUpdate("client-referral", null);
        } else {
            result = canCreate("client-referral", null);
        }

        return result;
    }

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const referralInfoComp = useRef();

    if (canRead("client-referral", null)) {

        return (
            <div className="refferal">
                <div className="referralDetails">
                    <div className="sectionTitle">Referral Details</div><br />
                    <div className="hidden">
                        <input type="hidden" name="client-file-id" value={clientFileId} /><br />
                        <input type="hidden" name="referral-id" value={referralId} /><br />
                    </div>
                    <ClientBasicInfo isReadOnly={true} clientFileId={clientFileId} ref={clientBasicInfoComp} isAliasVisible={true}/>
                    <ClientContactInfo isReadOnly={true} clientFileId={clientFileId} ref={clientContactInfoComp} contactMethods={contactMethods} />
                    <ReferralInfo clientFileId={clientFileId} referralId={referralId} ref={referralInfoComp} dutyCounselTypes={dutyCounselTypes} courtLocations={courtLocations} />
                    <div className="commandBar">
                        <input type="button" value="Save" disabled={canSave() ? "" : "disabled"} className={`${canSave() ? "" : "hidden"}`} onClick={onSaveClicked} /> &nbsp;&nbsp;
                        <input type="button" hidden={((null != referralId) && (referralId > 0)) ? "" : "hidden"} value="Delete" disabled={(canDelete("client-referral", null)) ? "" : "disabled"} className={`${canDelete("client-referral", null) ? "" : "hidden"}`} onClick={onDeleteClicked} /> &nbsp;&nbsp;
                        <input type="button" value="Cancel" onClick={() => navigate("/client-file?id=" + clientFileId)} />
                    </div>
                </div>
                <div className="clientFileFooter">&nbsp;</div>
            </div>
        );
    } else {
        return (
            <div className="refferal">
                <div className="referralDetails">
                    <div className="sectionTitle">Referral Details</div><br />
                    <div>You are not authorized to read this record.</div>
                </div>
            </div>
        );
    }
}

export default Referral;