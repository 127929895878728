import { Button } from "react-bootstrap";
import "./referrals.css";
import React, { useEffect, useState, useRef } from 'react';
import { useAuthorization } from "../hooks/useAuthorization";

function ReferralRow(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const formatDate = function (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    const referral = props.referral;
    if (canRead("client-referral", null)) {
        return (
            <tr>
                <td><a className="referralRow" href={'/referral?id=' + referral.referralId + '&clientFileId=' + props.clientFileId}>{referral.referralId}</a></td>
                <td>{referral.docketNumber}</td>
                <td>{formatDate(referral.upcomingCourtDate)}</td>
                <td>{referral.dutyCounselType.label}</td>
            </tr>
        );
    } else {
        return (<tr><td colspan="4">You are not authorized to read this record.</td></tr>);
    }
}

function ReferralTable(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const rows = [];

    if (props.referrals && props.referrals.length > 0) {
        props.referrals.forEach((referral) => {
            rows.push(
                <ReferralRow referral={referral} clientFileId={props.clientFileId} />
            );
        });
    }
    if (props.clientFileId && props.clientFileId > 0) {
    return (
        <div className="referralsSection">
            <div className="sectionTitle">{props.title}</div><br />
            <div className="referrals">
                <div>
                    <div>
                        <Button variant={(canCreate("client-referral", null)) ? "primary" : "secondary"} disabled={(canCreate("client-referral", null)) ? "" : "disabled"} className="ml-auto" href={'/referral?clientFileId=' + props.clientFileId}>New Referral</Button>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Referral Id</th>
                            <th>Docket Number</th>
                            <th>Upcoming Court Date</th>
                            <th>Duty Counsel Type</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </div >
        </div>
        );
    } else {
        return (
            <div className="referralsSection">
                <div className="sectionTitle">{props.title}</div><br />

                <div className="referrals">
                    Save the new Client File to add new Referral.
                </div >
            </div>
        );
    }
}

export default ReferralTable;
