import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import './main-page.css';
import Header from './header';
import Welcome from './welcome';
import HomeActions from './main-actions';
import Footer from './footer';
import Home from '../home';
import { WelcomeName } from './WelcomeName';
import ClientFile from '../client-file';
import Note from '../note';
import Referral from '../referral';
import AgencyRequest from '../feature/agency-requests/agency-request';

function App() {

  return (
    <Router>
      <div className="container">
        <Header subtitle="The law is complex. We're here to help." />

        <Routes>
          <Route path="/" element={
            <div>
              <Welcome message="Welcome to the Duty Counsel Portal!" />
              <HomeActions />
            </div>
          } />
                  <Route path="/dashboard" element={<Home />} />
                  <Route path="/client-file" element={<ClientFile />} />
                  <Route path="/note" element={<Note />} />
                  <Route path="/referral" element={<Referral />} />
                  <Route path="/agencyrequest" element={<AgencyRequest />} />
        </Routes>
        <Footer classification="Internal" />
      </div>
    </Router>
  );
}

export default App;
