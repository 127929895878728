import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "../auth/SignInButton,";
import { SignOutButton } from "../auth/SignOutButton";
import { useState, useEffect} from "react";
import ProfileContent from "./ProfileContent";


const AuthBlock = () => {

    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <div className="row">
                <div className="col-md-8 d-flex justify-content-end">
                     {isAuthenticated ? <ProfileContent /> : null}
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                    {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </div>
            </div>
        </>
            
    );
}



export default AuthBlock;
