import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientBasicInfo from "../../../client-basic-info";
import ClientContactInfo from "../../../client-contact-info";
import Select from 'react-select';
import "./style.css";
import appconfig from '../../../appConfig'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useValidation } from "../../../hooks/useValidation";
import { CreateAgencyRequest, GetAgencyRequestById, UpdateAgencyRequest, GetAgencyRequestMetaData, GetUserDetailsByRole, DeleteAgencyRequestById } from "../../../services/agency-request-service";
import * as Constants from "../../../helper/constants";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../../auth/authConfig";
import AttachmentList from '../../attachments/components/attachment-list';
import NoteTable from "../../../notes";

function AgencyRequest() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [isValid] = useValidation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [agencyRequestId, setAgencyRequestId] = useState(searchParams.get("id"));
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const [hasAllEditAfterClosedPermission, setHasAllEditAfterClosedPermission] = useState("");
    const [userId, setUserId] = useState("");

    const [docketNumber, setDocketNumber] = useState("");
    const [upcomingCourtDate, setUpcomingCourtDate] = useState("");
    const [courtLocation, setCourtLocation] = useState("");
    const [courtRoom, setCourtRoom] = useState("");
    const [instructionsText, setInstructionsText] = useState("");
    const [outcomeStatus, setOutcomeStatus] = useState("");
    const [completionNoteText, setCompletionNoteText] = useState("");
    const [requestor, setRequestor] = useState("");
    const [requestorError, setRequestorError] = useState(false);
    const [isReadOnlyDetail, setIsReadOnlyDetail] = useState(false);
    const [canChangeAssignedTo, setCanChangeAssignedTo] = useState(false);
    const [canChangeRequestor, setCanChangeRequestor] = useState(false);
    const [contactMethods, setContactMethods] = useState([]);

    const [docketNumberError, setDocketNumberError] = useState(false);
    const [upcomingCourtDateError, setUpcomingCourtDateError] = useState(false);
    const [courtLocationError, setCourtLocationError] = useState(false);
    const [courtRoomError, setCourtRoomError] = useState(false);
    const [instructionsTypeError, setInstructionsTypeError] = useState(false);
    const [courtLocations, setCourtLocations] = useState([]);
    const [outcomeStatuses, setOutcomeStatuses] = useState([]);

    const [assignedOn, setAssignedOn] = useState("");
    const [assignedTo, setAssignedTo] = useState("");
    const [assignedToError, setAssignedToError] = useState(false);
    const [assignedToDetails, setAssignedToDetails] = useState([]);

    const SELECTINDEX = "Please Select";
    const NAVIGATEURL = "/client-file?id=";
    const apiServerUrl = appconfig.API_SERVER_URL;

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();

    const [clientDetailNotes, setClientDetailNotes] = useState([]);

    // #region PageLoad
    useEffect(() => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount) {
            setUserId(currentAccount.localAccountId);
            setRequestor(currentAccount.name);
        }
    }, [instance]);


    useEffect(() => {

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'NoteService/GetClientDetailNotes?recordId=' + agencyRequestId + "&noteType=4";

            fetch(uri, options)
                .then(response => response.json())
                .then((response) => {
                    setClientDetailNotes(response)
                });
        };

        if (agencyRequestId != null && agencyRequestId > 0) {

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchData(response.accessToken);
                    }
                });
            }
        }

    }, [account, instance]);


    useEffect(() => {

        const fetchInitialData = async (token) => {

            const metaData = await GetAgencyRequestMetaData(token);
            if (metaData) {
                setCourtLocations(metaData.courtLocations);
                setOutcomeStatuses(metaData.outcomeStatuses);
            }
            else {
                alert("An error occurred while loading data.");
            }

            await GetUserDetailsByRole(token).then((data) => {
                setAssignedToDetails(data);

            });
            if (agencyRequestId && agencyRequestId > 0) {
                var data = await GetAgencyRequestById(agencyRequestId, token);
                setInitialAgencyRequest(data);
            }
            setInitialPermissions();
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }

    }, []);

    useEffect(() => {

        const FetchLookupData = async (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ClientFileService/GetClientFileLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setContactMethods(data.contactMethods);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken);
                }
            });
        }
    }, [account, instance]);

    const setInitialAgencyRequest = (agencyRequest) => {
        setDocketNumber(agencyRequest.docketNumber);
        setUpcomingCourtDate(Date.parse(agencyRequest.upcomingCourtDate));
        if (agencyRequest.assignedTo)
            setAssignedTo(JSON.parse(agencyRequest.assignedTo));
        setRequestor(JSON.parse(agencyRequest.requestor));
        setCourtLocation(JSON.parse(agencyRequest.courtLocation));
        setCourtRoom(agencyRequest.courtRoom);
        setInstructionsText(agencyRequest.instructions);
        setOutcomeStatus(agencyRequest.outcomeStatus);
        if (agencyRequest.assignedOn)
            setAssignedOn(agencyRequest.assignedOn);


        //#region SetPermissions
        var hasAllEditAfterClosedPermission = false;
        const editClosedRoleDetails = appconfig.AGENCYREQUEST_EDIT_AFTER_CLOSED_ROLES.split(";");
        if (editClosedRoleDetails)
            editClosedRoleDetails.forEach(roleValue => {
                if (account && account.idTokenClaims.roles[0].toString() == roleValue) {
                    hasAllEditAfterClosedPermission = true;
                }
            });
        setHasAllEditAfterClosedPermission(hasAllEditAfterClosedPermission);
        var hasAllEditOpenPermission = false;
        const editOpenRoleDetails = appconfig.AGENCYREQUEST_EDIT_OPEN_ROLES.split(";");
        if (editOpenRoleDetails)
            editOpenRoleDetails.forEach(roleValue => {
                if (account && account.idTokenClaims.roles[0].toString() == roleValue) {
                    hasAllEditOpenPermission = true;
                }
            });

        if (hasAllEditAfterClosedPermission) {
            setIsReadOnlyDetail(false);
            setCanChangeAssignedTo(false);
            setCanChangeRequestor(false);
        }
        else if (hasAllEditOpenPermission && agencyRequest && !agencyRequest.outcomeStatus) {
            setIsReadOnlyDetail(false);
            setCanChangeAssignedTo(false);
            setCanChangeRequestor(false);
        }
        else {
            if (agencyRequest && agencyRequest.outcomeStatus && agencyRequest.outcomeStatus > 0) {
                setIsReadOnlyDetail(true);
                setCanChangeAssignedTo(true);
                setCanChangeRequestor(true);
            }
            else {
                //ToDo: If logged in user = requestor
                if (account.localAccountId == JSON.parse(agencyRequest.requestor).value) {

                    setIsReadOnlyDetail(false);
                    setCanChangeAssignedTo(false);
                    setCanChangeRequestor(true);

                }
                else {
                    setCanChangeRequestor(false);
                }

                //check if the logged in user is the assigned to
                if (agencyRequest.assignedTo && agencyRequestId && account.localAccountId == JSON.parse(agencyRequest.assignedTo).value) {
                    setCanChangeRequestor(true);
                    setCanChangeAssignedTo(true);
                }
            }

        }
        //#endregion
    }

    const setInitialPermissions = () => {

        if (!agencyRequestId) {
            //set requestor by userRole
            const lawyers = appconfig.LAWYER_ROLE_NAMES.split(";");
            lawyers.forEach(roleValue => {
                if (account && account.idTokenClaims.roles[0].toString() == roleValue) {
                    assignedToDetails.forEach(element => {
                        if (element.value == userId) {
                            setRequestor(element);
                            setCanChangeRequestor(true);
                        }
                    });
                }
            });
        }
    }

    const createAgencyRequestObject = () => {
        const agencyRequest = {
            clientFileId: parseInt(clientFileId),
            docketNumber: docketNumber,
            upcomingCourtDate: new Date(upcomingCourtDate),
            courtLocation: JSON.stringify(courtLocation),
            courtRoom: courtRoom,
            instructions: instructionsText,
            Requestor: JSON.stringify(requestor)
        }
        if (assignedTo) {
            agencyRequest.AssignedTo = JSON.stringify(assignedTo);
        }
        //ToDo
        //Set the Assigned On after checking changes from backend
        if (agencyRequestId != null && agencyRequestId > 0) {
            agencyRequest.agencyRequestId = parseInt(agencyRequestId);
            agencyRequest.outcomeStatus = outcomeStatus > 0 ? parseInt(outcomeStatus) : null;
            agencyRequest.assignedOn = assignedOn;
        }

        return agencyRequest;
    }

    //#endregion

    //#region OnChangeEvents

    const onUpcomingCourtDateChanged = (date) => {
        setUpcomingCourtDate(new Date(date));
        setUpcomingCourtDateError(false);
    };
    const onCourtLocationChanged = (event) => {
        setCourtLocation(event);
        if (event && event.value)
            setCourtLocationError(false);
    };
    const onCourtRoomChanged = (event) => {
        setCourtRoom(event.target.value);

        if (event.target.value.length === 0)
            setCourtRoomError(false);
        else {
            const nameFieldModal = { name: Constants.DOCKETNUMBER, value: event.target.value, required: true };
            setCourtRoomError(!isValid(nameFieldModal));
        }
    };
    const onAssignedToChanged = (event) => {
        setAssignedTo(event);
    };
    const onRequestorChanged = (event) => {
        setRequestor(event);
        if (event && event.value)
            setRequestorError(false);
    };

    const onOutcomeStatusChanged = (event) => {
        setOutcomeStatus(event.target.value);
    };
    const onCompletionNoteTextChanged = (event) => {
        setCompletionNoteText(event.target.value);
    };
    const onDocketNumberChanged = (event) => {
        setDocketNumber(event.target.value);
        if (event.target.value.length == 0)
            setDocketNumberError(false);
        else {
            const nameFieldModal = { name: Constants.DOCKETNUMBER, value: event.target.value, required: true };
            setDocketNumberError(!isValid(nameFieldModal));
        }
    };
    const onInstructionsTextChanged = (event) => {
        setInstructionsText(event.target.value);
        if (event.target.value.length == 0)
            setInstructionsTypeError(false);
        else {
            const noteFieldModal = { name: Constants.NOTE, value: event.target.value, required: true };
            setInstructionsTypeError(!isValid(noteFieldModal));
        }
    };

    //#endregion

    //#region ButtonClickEvents

    const onSaveClicked = async (event) => {
        if (clientFileId && clientFileId > 0) {
            const isValidData = validateInformation();
            if (isValidData) {
                var agencyRequestDetails = createAgencyRequestObject();
                console.log(agencyRequestDetails);
                if (agencyRequestId != null && agencyRequestId > 0) {
                    if (account) {
                        instance.acquireTokenSilent(
                            tokenRequest
                        ).then((response) => {
                            if (response) {
                                UpdateAgencyRequest(agencyRequestDetails, response.accessToken).then(() => {
                                    navigate(NAVIGATEURL + clientFileId);
                                });
                            }
                        });
                    }
                }

                else {
                    if (account) {
                        instance.acquireTokenSilent(
                            tokenRequest
                        ).then((response) => {
                            if (response) {
                                CreateAgencyRequest(agencyRequestDetails, response.accessToken).then(() => {
                                    navigate(NAVIGATEURL + clientFileId);
                                });
                            }
                        });
                    }
                }
            }
        }
        else {
            alert("Cannot create an agency request. Agency request needs to have an associated client file.")
        }
    }

    const validateInformation = () => {
        var status = true;
        if (!docketNumber) { setDocketNumberError(true); status = false; }
        if (!upcomingCourtDate) { setUpcomingCourtDateError(true); status = false; }
        if (!courtLocation) { setCourtLocationError(true); status = false; }
        if (!requestor) { setRequestorError(true); status = false; }
        if (!courtRoom) { setCourtRoomError(true); status = false; }
        if (!instructionsText) { setInstructionsTypeError(true); status = false; }

        return status;
    }

    const onDeleteClicked = async () => {
        if (agencyRequestId != null && agencyRequestId > 0) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteAgencyRequestById(agencyRequestId, response.accessToken);
                    }
                });
            }
        }

        navigate(NAVIGATEURL + clientFileId);
    };

    const onCancelClicked = () => {
        // ToDo: Direct to JP Bail form or Interaction
        navigate(NAVIGATEURL + clientFileId);
    };

    //#endregion

    if (canRead("agency-request", null)) { //ToDo - Change this role

        return (
            <div className="agencyRequests">
                <div className="agencyRequest">
                    <div className="sectionTitle">Agency Request Details</div><br />
                    <div className="hidden">
                        <input type="hidden" name="client-file-id" value={clientFileId} /><br />
                        <input type="hidden" name="agency-request-id" value={agencyRequestId} /><br />
                    </div>
                    <ClientBasicInfo isReadOnly={true} clientFileId={clientFileId} ref={clientBasicInfoComp} isAliasVisible={false} />
                    <ClientContactInfo isReadOnly={true} clientFileId={clientFileId} ref={clientContactInfoComp} contactMethods={contactMethods} />

                    <div className="section">
                        
                        <label>Docket Number <span style={{ color: "red" }}>*</span></label> <br />
                        <input type="text" name="docket-number" value={docketNumber} onChange={onDocketNumberChanged} disabled={isReadOnlyDetail ? "disabled" : ""} /> <br />
                        {docketNumberError ? <span style={{ color: "red" }}>Docket number is required.</span> : null}
                        <br /><br />

                        <label>Upcoming Court Date <span style={{ color: "red" }}>*</span> <br /></label>
                        <DatePicker name="upcoming-court-date" selected={upcomingCourtDate} onChange={onUpcomingCourtDateChanged} disabled={isReadOnlyDetail ? "disabled" : ""} /> <br />
                        {upcomingCourtDateError ? <span style={{ color: "red" }}>Upcoming court date cannot be empty.</span> : null}
                        <br /><br />

                        <label>Court Location <span style={{ color: "red" }}>*</span> </label> <br />
                        <Select name="court-location" options={courtLocations} value={courtLocation} onChange={onCourtLocationChanged} isDisabled={isReadOnlyDetail} />
                        <br />
                        {courtLocationError ? <span style={{ color: "red" }}>Court location is required.</span> : null}
                        <br /><br />

                        <label>Court Room <span style={{ color: "red" }}>*</span> </label><br />
                        <input type="text" name="court-room" value={courtRoom} onChange={onCourtRoomChanged} disabled={isReadOnlyDetail ? "disabled" : ""} />
                        <br />
                        {courtRoomError ? <span style={{ color: "red" }}>Court room is required.</span> : null}
                        <br /><br />


                        <label> Instructions  <span style={{ color: "red" }}>*</span></label><br />
                        <textarea name="instructions-text" rows="4" cols="50" value={instructionsText} onChange={onInstructionsTextChanged} disabled={isReadOnlyDetail ? "disabled" : ""} /><br />
                        {instructionsTypeError ? <span style={{ color: "red" }}>Instructions are required.</span> : null}
                        <br /><br />
                        <label>Requestor <span style={{ color: "red" }}>*</span> </label><br />
                        <div>
                            <Select name="requestor" options={assignedToDetails} value={requestor} onChange={onRequestorChanged} isDisabled={canChangeRequestor} /> <br />
                            {requestorError ? <span style={{ color: "red" }}>Requestor is required.</span> : null}
                        </div>
                        <br /><br />
                        <label>Assigned To </label><br />
                        <div>
                            <Select name="assigned-to" options={assignedToDetails} value={assignedTo} onChange={onAssignedToChanged} isDisabled={canChangeAssignedTo} /> <br />
                        </div>
                        <br /><br />
                        {agencyRequestId != null && agencyRequestId > 0 &&
                            <>
                                <div> <label> Outcome Status  </label><br />
                                    <select name="outcome-status" value={outcomeStatus} onChange={onOutcomeStatusChanged} disabled={isReadOnlyDetail ? "disabled" : ""}>
                                        <option value="Please Select" >Please Select</option>
                                        {outcomeStatuses.map((option) => (
                                            <option value={option.id}>{option.label}</option>
                                        ))}
                                    </select><br />  <br />
                                    <NoteTable title="Completion Notes" notes={clientDetailNotes} clientFileId={clientFileId} relatedId={agencyRequestId} noteTypeId={4} />
                                </div>
                                <div className="section">
                                    <AttachmentList containerName="agency-requests" recordId={agencyRequestId}/>
                                </div>
                            </>
                        }
                    </div>
                    <div className="commandBar">
                        <input type="button" value="Save Agency Request" disabled="" className="" onClick={onSaveClicked} /> &nbsp;&nbsp;
                        <input type="button" hidden={((null != agencyRequestId) && (agencyRequestId > 0) && hasAllEditAfterClosedPermission) ? "" : "hidden"} value="Delete" onClick={onDeleteClicked} /> &nbsp;&nbsp;
                        <input type="button" value="Cancel" onClick={onCancelClicked} />
                    </div>
                </div>
                <div className="clientFileFooter">&nbsp;</div>
            </div>
        );
    } else {
        return (
            <div className="agencyRequests">
                <div className="agencyRequest">
                    <div className="sectionTitle">Agency Request Details</div><br />
                    <div>You are not authorized to read this record.</div>
                </div>
            </div>
        );
    }
}

export default AgencyRequest;