import appconfig from '../appConfig'

const SERVICENAME = "api/agencyrequests";
const CREATEREQUESTNAME = "/create";
const GETUSERSQUESTNAME = "/users/lawyers";
const GETMETADATAREQUESTNAME = "/metadata";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const CREATEFAILED = 'Create Agency Request Failed.';
const CREATESUCCESS = "Agency Request is successfully created.";
const UPDATESUCCESS ="Agency Request is successfully updated.";
const UPDATEFAILED = 'Update Agency Request Failed.';

export async function CreateAgencyRequest(agencyRequest, token) {
    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + CREATEREQUESTNAME;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(agencyRequest)
    }
    console.log(agencyRequest);

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            alert(CREATESUCCESS);
            return response.json();
        } else {
            alert(CREATEFAILED);
        }
    }).catch(error => { alert(CREATEFAILED); console.log(error); });

    return result;
}


export async function UpdateAgencyRequest(agencyRequest, token) {

    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +"/"+ agencyRequest.agencyRequestId;  

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(agencyRequest)
    }
    console.log(agencyRequest);

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            alert(UPDATESUCCESS);
            return response.json();
        } else {
            alert(UPDATEFAILED);
        }
    }).catch(error => { alert(UPDATEFAILED); console.log(error); });

    return result;
}

export async function GetAgencyRequestById(id, token) {

    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +"/"+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetAllAgencyRequest( clientFileId, token) {

    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+"?clientFileId="+clientFileId;   
    console.log(serviceUrl);
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetAgencyRequestMetaData( token) {

    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+GETMETADATAREQUESTNAME;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => {             
            var data = await response.json();           
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetUserDetailsByRole(token) {

    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+GETUSERSQUESTNAME;   
    console.log(serviceUrl);
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json();  
              
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function DeleteAgencyRequestById(id, token) {

    if (token == null) {
        alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +"/"+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}
