import "./client-financial-info.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import appconfig from '../appConfig'
import { tokenRequest } from "../auth/authConfig";
import { useAccount, useMsal } from "@azure/msal-react";

const ClientFinancialInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const apiServerUrl = appconfig.API_SERVER_URL;

    const [clientFinancialInformationId, setClientFinancialInformationId] = useState(0);
    const [financiallyEligible, setFinanciallyEligible] = useState(0);
    const [familySize, setFamilySize] = useState(1);
    const [familySizeError, setFamilySizeError] = useState(false);
    const [last12MonthsIncome, setLast12MonthsIncome] = useState(0);
    const [last12MonthsIncomeError, setLast12MonthsIncomeError] = useState(false);
    const [last30DaysIncome, setLast30DaysIncome] = useState(0);
    const [last30DaysIncomeError, setLast30DaysIncomeError] = useState(false);
    const [totalEquityValue, setTotalEquityValue] = useState(0);
    const [totalEquityValueError, setTotalEquityValueError] = useState(false);
    const [lastAssessmentDate, setLastAssessmentDate] = useState("");

    useImperativeHandle(ref, () => ({
        getClientFinancialInfo() {
            return {
                "clientFinancialInformationId": clientFinancialInformationId,
                "financiallyEligible": Boolean(financiallyEligible),
                "familySize": familySize,
                "last12MonthsIncome": last12MonthsIncome,
                "last30DaysIncome": last30DaysIncome,
                "totalEquityValue": totalEquityValue,
                "lastAssessmentDate": new Date(lastAssessmentDate)
            };
        }
    }));

    useEffect(() => {

        const FetchData = (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };

            const uri = apiServerUrl + 'ClientFileService/GetClientFinancialInformation?clientFileId=' + props.clientFileId;

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setClientFinancialInformationId(data.clientFinancialInformationId);
                    setFinanciallyEligible(Number(data.financiallyEligible));
                    setFamilySize(data.familySize);
                    setLast12MonthsIncome(data.last12MonthsIncome);
                    setLast30DaysIncome(data.last30DaysIncome);
                    setTotalEquityValue(data.totalEquityValue);
                    setLastAssessmentDate(Date.parse(data.lastAssessmentDate));
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        };


        if (props.clientFileId) {
            if (account) {
                instance.acquireTokenSilent(
                  tokenRequest
                ).then((response) => {
                  if (response) {
                    FetchData(response.accessToken);
                  }
                });
              }
        }
    }, [account, instance]);


    return (
        <div className={`section ${props.isCRMOnly ? "crm-only" : ""}`}>
            <div className="sectionTitle">Financial Information</div><br />
            <div className="hidden">
                <input type="hidden" name="client-financial-info-id" disabled={props.isCRMOnly ? "disabled" : ""} value={clientFinancialInformationId} /><br />
            </div>
            <label>
                Financially Eligible  </label><br />
            <select name="financially-eligible" disabled={props.isCRMOnly ? "disabled" : ""} value={financiallyEligible} >
                <option value={0} >No</option>
                <option value={1} >Yes</option>
            </select>

            <br /><br />
            <label>
                Family Size  </label><br />
            <input type="number" name="family-size" disabled={props.isCRMOnly ? "disabled" : ""} value={familySize} /> <br />


            <br /><br />
            <label>
                Last 12 Months Income   </label><br />
            <input type="number" name="last-12-months-income" disabled={props.isCRMOnly ? "disabled" : ""} value={last12MonthsIncome} /> <br />

            <br /><br />
            <label>
                Last 30 Days Income </label><br />
            <input type="number" name="last-30-days-income" disabled={props.isCRMOnly ? "disabled" : ""} value={last30DaysIncome} /> <br />

            <br /><br />
            <label>
                Total Equity Value  </label><br />
            <input type="number" name="total-equity-value" disabled={props.isCRMOnly ? "disabled" : ""} value={totalEquityValue} /> <br />
            <br /><br />
            <label>
                Last Assessment Date </label><br />
            <DatePicker name="last-assesment-date" disabled={props.isCRMOnly ? "disabled" : ""}
                selected={lastAssessmentDate}
            /> <br />
            <br /><br />

        </div>
    );
})

export default ClientFinancialInfo;