import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { DeleteAttachment, DownloadAttachments } from "../../../services/storage-service";
import { tokenRequest } from "../../../auth/authConfig";
import saveAs from "file-saver";
import { GetAttachments } from "../../../services/storage-service";


const AttachmentRow = ({ attachment, containerName, recordId, setAttachment }: any) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const loadFiles = () => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }
    }

    const fetchInitialData = async (token: string) => {
        await GetAttachments(containerName, recordId, token).then((data) => {
            console.log(data);
            setAttachment(data);
        });
    }
    

    const handleDownloadClick = () => {
     
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    var fileDetails: any = await DownloadAttachments(containerName, recordId, attachment.name, response.accessToken);
                    var stream = new Response(fileDetails.body)
                    var blobdetails = await stream.blob();
                    saveAs(blobdetails, attachment.name);
                }
            });
        }
    };

    const onDeleteClicked = async () => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    const status = await DeleteAttachment(containerName, recordId, attachment.name, response.accessToken);
                    if (status)
                    {
                        alert("File successfully deleted.");
                        loadFiles();
                    }                       
                    else
                        alert("Could not delete file. Please try again.");
                }
            });
        }
    };

    return (
        <>
            <tr>
                <td>
                    <a href="#attachment-component" onClick={handleDownloadClick}>
                        {attachment.name}
                    </a>
                </td>
                <td>
                    <Button className="btn btn-danger" onClick={onDeleteClicked}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                        </svg>
                    </Button>
                </td>
            </tr>
        </>
    );
}

export default AttachmentRow;