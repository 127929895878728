import { useIsAuthenticated } from "@azure/msal-react";
import AuthBlock from "../auth/AuthBlock";
import { SignInButton } from "../auth/SignInButton,";
import { SignOutButton } from "../auth/SignOutButton";
import logo from "./logo.png";


const Header = ({ subtitle }: { subtitle: string }) => {

  return (
    <header className="row header d-flex pt-3 pb-3 align-items-center">
      <div className="col-md-4">
        <img src={logo} className="logo" alt="logo" />
      </div>
      <div className="col-md-4 subtitle">{subtitle}</div>
      <div className="col-md-4">
        <AuthBlock />
      </div>
    </header>
  );
}



export default Header;
